@font-face {
  font-family: "Gotham-Light";
  src: url("./assets/fonts/Gotham/GothamLight.ttf");
}

@font-face {
  font-family: "Gotham-Medium";
  src: url("./assets/fonts/Gotham/GothamMedium.ttf");
}

@font-face {
  font-family: "Gotham-Bold";
  src: url("./assets/fonts/Gotham/GothamBold.ttf");
}

@font-face {
  font-family: "Aeonik-Regular";
  src: url("./assets/fonts/Aeonik/AeonikTRIAL-Regular.otf");
}

body {
  background-color: #f1f1f1;
}

.gotham-light {
  font-family: "Gotham-Light" !important;
}

.gotham-medium {
  font-family: "Gotham-Medium" !important;
}

.gotham-bold {
  font-family: "Gotham-Bold" !important;
}

.aeonik-regular {
  font-family: "Aeonik-Regular" !important;
}

/* Custom Phone Input styling */

.react-international-phone-input-container {
  width: 100% !important;
}
.react-international-phone-country-selector button {
  height: 38px !important;
  padding-left: 24px !important;
  border: none !important;
  background-color: #f2f2f2 !important;
  border-radius: 8px 0px 0px 8px !important;
}
.react-international-phone-input {
  height: 38px !important;
  border: none !important;
  border-radius: 0px 8px 8px 0px !important;
  background-color: #f2f2f2 !important;
  width: 100% !important;
  padding-left: 24px !important;
  padding-right: 24px !important;
  font-size: 12px !important;
  font-weight: 400;
  font-family: "Aeonik-Regular" !important;
}

/* Hide scrollbar */
/* width */
.sidebar ::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
}

/* Track */
.sidebar ::-webkit-scrollbar-track {
  background: #0b0a0a;
}

/* Handle */
.sidebar ::-webkit-scrollbar-thumb {
  background: #908b8b !important;
  border-radius: 99px !important;
}

/* Handle on hover */
.sidebar ::-webkit-scrollbar-thumb:hover {
  background: #908b8b !important;
}
